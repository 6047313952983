<template>
    <Error404 image="hibou.png">
        <template v-slot:title>Hou Hou!</template>
        <template v-slot:text>
            La page demandée est introuvable!<br/>
            Vous allez être redirigé sur la page d'accueil.
        </template>
    </Error404>
</template>

<script>
    import router from '../router/index';
    import Error404 from '../components/404.vue';

    export default {
        name: '404',
        mounted() {
            // 404 page stays only opened 4 seconds before pushing Home to the router
            window.scrollTo(0, 0); // mériterait d'être dégagé dans le routeur (DRY)
            document.title = 'Erreur 404!'
            setTimeout(() => {
                router.push('/')
            }, 4000);
        },
        components: {
            Error404
        }
    }
</script>


<style lang="scss" scoped>
</style>