<template>
<!-- 
    404 template with 2 slots and imagesrc prop :
    prop: image - filename, file located in assets/img
    slots : 
        - title
        - text
 -->
    <div id="page-content">
        <div class="container error-404 py-4">
            <div class="row d-lg-flex align-items-center text-center">
                <div class="col-12 col-lg-4">
                    <img class="error-404__img mb-2 mb-lg-0" :src="imageSrc" alt="Hibou">
                </div>
                <div class="col col-lg-8 error-404__text rounded shadow">
                    <h2 class="error-404__text__heading mb-n2">
                        <slot name="title">Erreur!</slot>
                    </h2>
                    <h3 class="error-404__text__404 mt-n4">404</h3>
                    <p class="error-404__text__paragraph">
                        <slot name="text">Page Introuvable!</slot>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Error404',
        props: {
            image: {
                type: String,
                required: true
            }
        },
        computed: {
            imageSrc() {
                return require(`@/assets/img/${this.image}`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../sass/vendors/overrides";
    @import "../sass/utils/mixins";

    .error-404 {

        &__img {
            max-width: min(400px, 100%);
        }

        &__text {
            @include smoked-frame();
            font-family: 'Bangers', cursive;
            text-shadow: 3px 3px 3px rgba(#000, 0.4);


            &__heading {
                font-size: min(5em, 20vw);
                font-style: italic;
                color: lighten($primary, 10);

            }

            &__404 {
                font-size: min(15em, 30vw);
                color: lighten($primary, 10);
            }

            &__paragraph {
                font-size: min(2.5em, 8vw);
                text-align: left;

            }
        }

    }
</style>